<template>
    <div>
        <div class="container">
            <div class="form-row">
                <div class="form-group col-md-2 form-row report-header">
                    <div class="col-10 text-center">
                        <h5>Vuelos</h5>
                    </div>
                  <div class="col-md-10">
                      <label for="FlightNumber" >Número de Vuelo</label>
                      <input v-model="FlightNumber" name="FlightNumber" field-editor="FlightNumber"
                      class="form-control" type="text" placeholder="" id="FlightNumber">
                  </div>
                  <div class="col-md-10">
                      <label for="FromDate">Desde</label>
                      <datepicker id="FromDate" :def.sync="FromDate" @changedate="checkDate('FromDate')"></datepicker>
                      <div class="invalid-feedback">
                        Campo requerido
                      </div>
                  </div>
                  <div class="col-md-10">
                      <label for="ToDate">Hasta</label>
                      <datepicker id="ToDate" :def.sync="ToDate" @changedate="checkDate('ToDate')"></datepicker>
                      <div class="invalid-feedback">
                        Campo requerido
                      </div>
                  </div>
                  <div class="col-md-10">
                      <selectinput fieldname="WeekDay" :fieldOptions="fieldOptions" label="Día"
                      :current_value.sync="WeekDay">
                      </selectinput>
                  </div>
                  <div class="col-md-10">
                      <selectinput fieldname="Status" :fieldOptions="fieldOptions" label="Estado"
                      :current_value.sync="Status">
                      </selectinput>
                  </div>
                  <div class="col-md-10">
                    <div class="spacer"></div>
                    <button type="button" class="btn btn-primary" @click="searchFlights">Buscar</button>
                  </div>
                  <div class="col-md-10">
                    <div class="spacer"></div>
                    <button type="button" class="btn btn-primary" @click="addFlight">Nuevo</button>
                  </div>
                  <div class="col-md-10">
                    <div class="spacer"></div>
                    <button type="button" class="btn btn-primary" @click="addManyFlight">Agregar por Período</button>
                  </div>
                </div>
                <div class="form-group col-md-6">
                    <v-client-table :data="list" :columns="columnsNames" :options="options" v-if="!processing && list"
                        @row-click="onRowClick">
                    </v-client-table>
                    <div v-if="processing">
                        <img class="loading-gif" src="@/img/loading.gif">
                    </div>
                </div>
            </div>
        </div>


        <recordwindow v-if="current" endpoint="/api/flight/" :fields="fields" :title="params.title" :currentrecord="current"
        :title2="params.title2" :afteredit="params.afteredit" @close="closeCurrent" modalClass="modal-large"></recordwindow>

    </div>
</template>

<script>
import '@/css/app.css';

import { mapState } from 'vuex';
import datepicker from '@/components/tools/DatePicker'
import recordwindow from '@/components/tools/RecordWindow';
import selectinput from '@/components/tools/SelectInput'
import Vue from 'vue/dist/vue.esm'
import {ClientTable, Event} from 'vue-tables-2'
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);

var moment = require('moment');
export default {
  name: 'editflight',
  components: {

    datepicker,
    selectinput,
    recordwindow
  },
  data  () {
    return {
        current: null,
        datalist: [],
        fields: app.getFlightFields(),
        params: {
            serverside: true,
            title: 'Vuelo',
            title2: 'Vuelo',
            afteredit: app.flighAfteredit()
        },
        FlightNumber: null,
        FromDate: moment(new Date()).format("YYYY-MM-DD"),
        ToDate: moment(new Date()).format("YYYY-MM-DD"),
        WeekDay: null,
        Status: null,
        list: null,
        processing: false,
        fieldOptions: {
            WeekDay: [
                {value: '0', label: 'Lunes'},
                {value: '1', label: 'Martes'},
                {value: '2', label: 'Miércoles'},
                {value: '3', label: 'Jueves'},
                {value: '4', label: 'Viernes'},
                {value: '5', label: 'Sábado'},
                {value: '6', label: 'Domingo'},
            ],
            Status: [
                {value: 'CONFIRMED', label: 'Confirmado'},
                {value: 'SUSPENDED', label: 'Suspendido'},
                {value: 'CANCELED', label: 'Cancelado'},
            ]
        },
        columnsNames: ['FlightNumber', 'FlightDate', 'WeekDay', 'Status'],
        options: {
            perPageValues: [5, 10, 15, 20, 25, 50],
            perPage: 15,
                headings: {},
                //destroyEventBus: true,
                texts: app.vueTableText(),
                templates: {},
                skin: 'table table-striped  table-sm table-bordered',
            }
     }
  },
  computed: {
    ...mapState({
        user: state => state.user,
    }),
    myfields  () {
        return app.myfields(this.fields);
    },

    /*myfields () {
        return [
            {name: 'WeekDay', editor: 'select', options: this.fieldOptions.WeekDay, label: 'Día'},
            {name: 'Status', editor: 'select', options: this.fieldOptions.Status, label: 'Estado'},
            {name: 'FlightDate', label: 'Fecha', dateformat: 'DD/MM/YYYY'},
            {name: 'FlightNumber', label: 'Vuelo'},
        ]
    },*/
    getTemplates () {
        let self = this;
        let res = {}
        for (let field of this.myfields) {
            res[field.name] = function(t, row) {
                    return self.getDisplayValue(row, field);
                }
        }
        return res;
    },
    getHeadings () {
        let res = {};
        for (let f of this.myfields) {
            if (f.type=='password') continue;
            if (f.hidden) continue;
            if (f.label) {
                res[f.name] = f.label;
            }
        }
        return res;
    },

  },
  mounted () {
    this.options.templates = this.getTemplates;
    this.options.headings = this.getHeadings;
  },
  methods: {
    showRecord: async function(c) {
      let syncVersion = await app.get('/api/get_sync_version/flight/' + c.id);
      if (syncVersion && syncVersion!=c.syncVersion) {
        c = await app.get('/api/flight/' + c.id);
        if (c)  {
            c.original_id = c.id;
            this.current = c;
        }
      } else {
        c.original_id = c.id;
        this.current = c;
      }
      this.$nextTick(function () {
        $('#modal-abm-window').modal('show')
      })
    },
    closeCurrent () {
       $('#modal-abm-window').modal('hide');
       this.current = false;
    },
    checkDate (fieldname) {
        /*if (moment(this[fieldname]) < moment(new Date())) {
            let self = this;
            this.$nextTick(function() {
                self[fieldname] = moment(new Date()).format("YYYY-MM-DD");
            })
        }*/
    },
    setInvalidClass (id, fieldname) {
        let div = $("#" + id)[0];
        div.classList.remove('is-invalid')
        if (!this[fieldname]) {
            div.classList.add('is-invalid')
            return true;
        }
        return false;
    },
    searchFlights: async function() {
        let required = false;
        if (this.setInvalidClass('date-picker-inputToDate', 'ToDate'));
        if (required) return;
        this.processing = true;
        let data = {
            FlightNumber: this.FlightNumber,
            FromDate: this.FromDate,
            ToDate: this.ToDate,
            WeekDay: this.WeekDay,
            Status: this.Status
        }
        let res = await app.get('/api/flight/get_flights', data)
        if (res) {
            this.list = res;
            this.processing = false;
        }
    },
    getDisplayValue (record, field) {
        return app.getDisplayValue(this.fieldOptions, record, field)
    },
    onRowClick (data) {
        data.row.invalid = {
          FlightFares: false
        };
        this.showRecord(data.row);
    },
    addFlight () {
      app.addNew(this);
      this.current.invalid = {
          FlightFares: false
      }
      this.$nextTick(function () {
        $('#modal-abm-window').modal('show')
      })
    },
    addManyFlight () {
      this.$router.push('/addmany');
    }

  },
}
</script>

